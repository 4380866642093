import React from 'react'
import { graphql } from 'gatsby'

// Components
import {
  TitleDefault,
  BannerDefault,
  ContentDefault,
  BoxDefault
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

import 'styles/templates/PartnersTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        pageTitle: page_title

        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PartnersTemplate = ({ 
  data: {
    page: { path, content, yoast_meta, acf },
  },
 }) => {
  return (
    <Layout backgroundImage={acf.backgroundImage}>
      {acf.backgroundImage && acf.backgroundImage.localFile ? (
        <SEO yoast={{ meta: yoast_meta }} pathname={path} image={acf.backgroundImage.localFile.childImageSharp.fixed.src} article />
      ) : (
        <SEO yoast={{ meta: yoast_meta }} pathname={path} />
      )}
      <div className="partners-template">
        <BannerDefault className="py-5">
          <div className="row">
            <div className="col-12 col-lg-6 py-4">
              <TitleDefault asH1 className="pl-lg-5">
                {acf.pageTitle}
              </TitleDefault>
            </div>
            <div className="col-12 col-lg-6" />
          </div>
        </BannerDefault>

        <div className="container pb-5">
          <BoxDefault>
            <ContentDefault className="py-4 px-3 px-lg-5">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </ContentDefault>
          </BoxDefault>
        </div>
      </div>
    </Layout>
  )
}

export default PartnersTemplate